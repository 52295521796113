import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useClickOutside from '../hooks/useClickOutside';
import Text from '../styles/typography';
import { Button } from '../styles/buttons';
import { spacing, colors, minWidthCss } from '../styles/theme';

import Checkbox from './checkbox';

const SortContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	position: relative;

	${minWidthCss.sm`
     margin: 0 20px;
  `};

	${minWidthCss.m`
     margin: 0 55px;
  `};
`;

const SortButton = styled(Button)`
	background-color: ${props =>
		props.selected ? `${colors.lightGrey}` : 'transparent'};
	padding: ${spacing[0]} ${spacing[1]};
	border-bottom: none;
`;

const Arrow = styled(Text.Span)`
	transform: ${props => (props.selected ? 'rotate(-180deg)' : 'rotate(0)')};
	transition: transform 0.3s;
	margin-left: ${spacing[0]};
	display: inline-block;
`;

const DropdownContainer = styled.div`
	display: none;
	flex-direction: column;
	background-color: ${colors.lightGrey};
	width: 170px;
	padding: ${spacing[1]};
	opacity: ${props => (props.showSort ? '1' : '0')};
	transition: opacity 0.3s;
	position: absolute;
	top: 100%;
	z-index: 10;

	${minWidthCss.sm`
    display: flex;
  `}
`;

// Mobile styles
const MobileContainer = styled.div`
	background-color: ${colors.lightGrey};
	display: ${props => (props.showSort ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 10;
	padding-bottom: ${spacing[4]};

	${minWidthCss.sm`
    display: none;
  `}
`;

const ButtonMobile = styled(Button)`
	background-color: ${colors.white};
	position: fixed;
	bottom: 0;
	width: 100%;
	border-bottom: none;
	padding: ${spacing[1]} 0;
	box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.15);
`;

const MobileHeader = styled.div`
	padding: ${spacing[2]} ${spacing[1]};
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${colors.mediumGrey};
`;

const FormatButtonMobile = styled(Button)`
	padding: ${spacing[2]} ${spacing[1]};
	display: flex;
	justify-content: space-between;
	border-bottom: none;
	width: 100%;
	background-color: transparent;
	border-bottom: 1px solid ${colors.mediumGrey};
`;

const formats = [
	{ title: 'All Formats', tag: 'all' },
	{ title: 'Features', tag: 'features' },
	{ title: 'Interviews', tag: 'interview' },
	{ title: 'Photo Essays', tag: 'photo-essay' },
	{ title: 'Videos', tag: 'video', includes: ['studio-science'] },
	{ title: 'Lectures', tag: 'lecture', includes: ['lecture-video'] },
];

const SortFormat = ({ onSubmit }) => {
	const [showSort, setShowSort] = useState(false);
	const [selectedFormat, setSelectedFormat] = useState('All Formats');
	const handleOpen = () => setShowSort(!showSort);
	const { ref } = useClickOutside(() => setShowSort(false));
	const handleChange = event => {
		const { name } = event.target;
		setSelectedFormat(name);
	};

	useEffect(() => {
		const selected = formats.filter(format => format.title === selectedFormat);
		const tags = selected[0].includes
			? [{ tag: selected[0].tag, includes: selected[0].includes }]
			: [{ tag: selected[0].tag, includes: [] }];

		const tagToSubmit =
			selectedFormat && selectedFormat === 'All Formats' ? {} : tags;

		onSubmit(tagToSubmit);
	}, [selectedFormat]);

	// Mobile functions
	const handleSelectFormat = name => () => {
		setSelectedFormat(name);
		setShowSort(false);
	};

	const closeMobileMenu = () => setShowSort(false);

	return (
		<SortContainer ref={ref}>
			<SortButton onClick={handleOpen} selected={showSort} small>
				<Text.Span size="m">Sort</Text.Span>
				<Arrow selected={showSort} size="ml">
					↓
				</Arrow>
			</SortButton>
			<MobileContainer showSort={showSort}>
				<MobileHeader>
					<Text.P color="grey">Sort</Text.P>
				</MobileHeader>
				<For each="format" of={formats}>
					<FormatButtonMobile
						key={format.title}
						onClick={handleSelectFormat(format.title)}
					>
						<Text.Span>{format.title}</Text.Span>
						<Text.Span>→</Text.Span>
					</FormatButtonMobile>
				</For>
				<ButtonMobile onClick={closeMobileMenu}>
					<Text.Span>Close</Text.Span>
				</ButtonMobile>
			</MobileContainer>
			<DropdownContainer showSort={showSort}>
				<For each="format" of={formats}>
					<Checkbox
						checked={selectedFormat === format.title}
						key={format.title}
						name={format.title}
						onChange={handleChange}
					/>
				</For>
			</DropdownContainer>
		</SortContainer>
	);
};

SortFormat.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default SortFormat;
