import { css } from 'styled-components';

const theme = {
	/**
	 * Breakpoints:
	 * s: 375px
	 * sm: 600px
	 * m: 900px
	 * l: 1200px
	 * xl: 1600px
	 */
	breakpoints: {
		s: '23.4375em',
		sm: '37.5em',
		m: '56.25em',
		l: '75em',
		xl: '100em',
	},
	colors: {
		black: '#000000',
		grey: '#999999',
		mediumGrey: ' #e5e5e5',
		lightGrey: '#f2f2f2',
		placeholder: '#bcbcbc',
		white: '#fff',
		red: '#ff0000',
	},
	contentMargins: [
		'9.375rem', // 150px
		'20%',
		'25%',
	],
	/**
	 * Spacing:
	 * [0]: 10px
	 * [1]: 15px
	 * [2]: 20px
	 * [3]: 40px
	 * [4]: 60px
	 * [5]: 80px
	 */
	spacing: ['0.625rem', '0.9375rem', '1.25rem', '2.5rem', '3.75rem', '5rem'],
	/**
	 * Font size:
	 * [0]: 10px
	 * [1]: 12px
	 * [2]: 16px
	 * [3]: 20px
	 * [4]: 30px
	 * [5]: 45px
	 * [6]: 60px
	 * [7]: 100px
	 * [9]: 120px
	 */
	fontSize: [
		'0.625rem',
		'0.75rem',
		'1rem',
		'1.25rem',
		'1.875rem',
		'2.8125rem',
		'3.75rem',
		'6.25rem',
		'7.5rem',
	],
	root: '16',
	siteMaxWidth: '112.5rem', // 1800px
	get maxWidthCss() {
		return Object.entries(this.breakpoints).reduce(
			(all, [name, bp]) => ({
				...all,
				[name]: (...args) => css`
					@media (max-width: ${bp}) {
						${css(...args)};
					}
				`,
			}),
			{}
		);
	},
	get minWidthCss() {
		return Object.entries(this.breakpoints).reduce(
			(all, [name, bp]) => ({
				...all,
				[name]: (...args) => css`
					@media (min-width: ${bp}) {
						${css(...args)};
					}
				`,
			}),
			{}
		);
	},
};

export default theme;
export const {
	breakpoints,
	colors,
	contentMargins,
	fontSize,
	maxWidthCss,
	minWidthCss,
	root,
	siteMaxWidth,
	spacing,
} = theme;
