import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '../styles/typography';
import { Button } from '../styles/buttons';
import { colors, spacing, minWidthCss } from '../styles/theme';

import SelectedTags from './selected-tags';
import Checkbox from './checkbox';

const MobileButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 0;

	${minWidthCss.sm`
    padding-left: 1rem;
  `}

	${minWidthCss.m`
    padding-left: 0;
  `}
`;

const CategoryTitle = styled(Text.H2)`
	padding: ${spacing[2]} 0 0 ${spacing[1]};
`;

const ResetButton = styled(Button)`
	border-bottom: none;
	background-color: transparent;
`;

const ButtonMobile = styled(Button)`
	background-color: ${props => (props.black ? colors.black : colors.white)};
	color: ${props => (props.black ? colors.white : colors.black)};
	position: fixed;
	bottom: 0;
	width: 100%;
	border-bottom: none;
	padding: ${spacing[1]} 0;
	box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.06);
`;

const ButtonArrow = styled(Text.Span)`
	position: absolute;
	left: 15px;
`;

const MenuMobile = styled.div`
  background-color: ${colors.lightGrey};
  display: ${props => (props.showFilter ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  padding-bottom: ${spacing[4]};
}
`;

const TagWrapperMobile = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: ${spacing[2]} 0 0 ${spacing[1]};
`;

const CategoryButtonMobile = styled(Button)`
	padding: ${spacing[2]} ${spacing[1]};
	display: flex;
	justify-content: space-between;
	border-bottom: none;
	width: 100%;
	background-color: transparent;
	border-bottom: 1px solid ${colors.mediumGrey};
`;

const MobileContainer = styled.div`
	display: block;

	${minWidthCss.m`
    display: none;
 `}
`;

const FilterMobileHeader = styled.div`
	padding: ${spacing[2]} ${spacing[1]};
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${colors.mediumGrey};
`;

const FilterButton = styled(Button)`
	border-bottom: none;
	display: block;
	margin: ${spacing[1]} 1rem;

	${minWidthCss.sm`
    margin: ${spacing[1]} ${spacing[2]};
  `}

	${minWidthCss.m`
    display: none;
  `}
`;

const FilterMobile = ({
	setShowFilter,
	checkedTags,
	handleReset,
	handleSelect,
	handleChange,
	showFilter,
	setCategoriesMobile,
	data,
	showCategories,
	activeTab,
	setTab,
	allTags,
}) => {
	const setFilter = () => setShowFilter(true);
	const setHideFilter = () => setShowFilter(false);
	const setCategories = () => setCategoriesMobile(true);
	const resetAll = () => handleReset('all');
	const selectCategory = category => () => {
		setTab(category);
		setCategoriesMobile(false);
	};
	return (
		<MobileContainer>
			<MobileButtonWrapper>
				<FilterButton onClick={setFilter} small>
					<Text.Span>Filter ↓</Text.Span>
				</FilterButton>
				<If condition={Object.values(checkedTags).includes(true)}>
					<ResetButton onClick={resetAll} small>
						Reset
					</ResetButton>
				</If>
			</MobileButtonWrapper>
			<MenuMobile showFilter={showFilter}>
				<FilterMobileHeader>
					<Text.P color="grey">Filter</Text.P>
					<If condition={Object.values(checkedTags).includes(true)}>
						<ResetButton onClick={resetAll} small>
							Reset
						</ResetButton>
					</If>
				</FilterMobileHeader>
				<For each="tagCategory" index="index" of={data}>
					<If condition={showCategories}>
						<CategoryButtonMobile
							key={`${tagCategory.category}_${index}`}
							onClick={selectCategory(tagCategory.category)}
						>
							<Text.Span>{tagCategory.category}</Text.Span>
							<Text.Span>→</Text.Span>
						</CategoryButtonMobile>
					</If>
					<If condition={tagCategory.category === activeTab && !showCategories}>
						<CategoryTitle size="ml">{tagCategory.category}</CategoryTitle>
						<TagWrapperMobile key={tagCategory.tag}>
							<For each="tagName" index="index" of={tagCategory.tags}>
								<Checkbox
									checked={checkedTags[tagName.tag]}
									isMobile
									key={`${tagName.tag}_${index}`}
									name={tagName.tag}
									onChange={handleChange(tagName.includes)}
								/>
							</For>
						</TagWrapperMobile>
					</If>
					<Choose>
						<When
							condition={
								showCategories && !Object.values(checkedTags).includes(true)
							}
						>
							<ButtonMobile onClick={setHideFilter}>
								<Text.Span>Close</Text.Span>
							</ButtonMobile>
						</When>
						<When
							condition={
								!showCategories && Object.values(checkedTags).includes(true)
							}
						>
							<ButtonMobile black onClick={setCategories}>
								<Text.Span>Apply</Text.Span>
							</ButtonMobile>
						</When>
						<When condition={!showCategories}>
							<ButtonMobile onClick={setCategories}>
								<ButtonArrow>←</ButtonArrow>
								<Text.Span>Back</Text.Span>
							</ButtonMobile>
						</When>
						<When
							condition={
								showCategories && Object.values(checkedTags).includes(true)
							}
						>
							<ButtonMobile
								black
								onClick={handleSelect(
									tagCategory.category,
									showFilter && activeTab === tagCategory.category
								)}
							>
								<Text.Span>View Results</Text.Span>
							</ButtonMobile>
						</When>
					</Choose>
				</For>
				<If condition={showCategories}>
					<SelectedTags
						allTags={allTags}
						checkedTags={checkedTags}
						handleReset={handleReset}
						isMobile
					/>
				</If>
			</MenuMobile>
		</MobileContainer>
	);
};

FilterMobile.propTypes = {
	setShowFilter: PropTypes.func,
	checkedTags: PropTypes.object,
	handleReset: PropTypes.func,
	handleSelect: PropTypes.func,
	handleChange: PropTypes.func,
	showFilter: PropTypes.bool,
	setCategoriesMobile: PropTypes.func,
	data: PropTypes.array,
	showCategories: PropTypes.bool,
	activeTab: PropTypes.string,
	setTab: PropTypes.func,
	allTags: PropTypes.array,
};

export default FilterMobile;
