import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import LinkItems from '../components/link-items';
import Header from '../components/header';
import SEO from '../components/seo';
import useExcludeTypes from '../hooks/useExcludeTypes';

const Author = ({ data, pageContext }) => {
	const { pageTitle, header, slug } = pageContext;

	const items = useExcludeTypes(data.allAuthorsJson.nodes[0].items);
	items.sort((a, b) => {
		if (a.firstPublished < b.firstPublished) return 1;
		if (a.firstPublished > b.firstPublished) return -1;
		return 0;
	});

	return (
		<Layout stickyNav>
			<SEO pathname={`/author/${slug}`} title={pageTitle} />
			<Header
				isAuthorPage
				pageSubtitle={pageContext.description}
				pageTitle={header}
			/>
			<LinkItems columns={3} data={items} randomize={false} />
		</Layout>
	);
};

Author.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

export default Author;

export const authorQuery = graphql`
	query authorQuery($slug: String!) {
		allAuthorsJson(filter: { slug: { eq: $slug } }) {
			nodes {
				items {
					title
					slug
					_id
					type
					teaserTitle
					teaserSubtitle
					teaserImage {
						id
					}
					tags {
						text
					}
					firstPublished
				}
			}
		}
	}
`;
