import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import LinkItems from '../components/link-items';
import Header from '../components/header';
import SEO from '../components/seo';
import useExcludeTypes from '../hooks/useExcludeTypes';

const Page = ({ data, pageContext }) => {
	const { pageTitle, header, slug, metaImage } = pageContext;
	const isNavigationPage = ['features', 'videos', 'interviews'].includes(slug);
	const isThreeColumn = !isNavigationPage || ['interviews'].includes(slug);
	const items = useExcludeTypes(data.allCollectionsJson.nodes[0].items);
	const {
		description,
		teaserSubtitle,
	} = data.allCollectionsJson.nodes[0].collection;
	return (
		<Layout stickyNav>
			<SEO
				description={description || teaserSubtitle}
				image={metaImage && `${metaImage}?w=1080`}
				pathname={`/${slug}`}
				title={pageTitle}
			/>
			<Header
				currentPage={slug}
				hasLabel={isNavigationPage}
				isAuthorPage={false}
				isNavigationPage={isNavigationPage}
				pageSubtitle={isNavigationPage ? null : pageContext.description}
				pageTitle={isNavigationPage ? pageContext.description : header}
			/>
			<LinkItems
				columns={isThreeColumn ? 3 : 2}
				data={items}
				randomize={isNavigationPage}
				showFilter={isNavigationPage}
				showSort={!isNavigationPage}
			/>
		</Layout>
	);
};

Page.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

export default Page;

export const pageQuery = graphql`
	query pageQuery($slug: String!) {
		allCollectionsJson(filter: { collection: { slug: { eq: $slug } } }) {
			nodes {
				collection {
					description
					teaserSubtitle
				}
				items {
					title
					slug
					_id
					type
					teaserTitle
					teaserSubtitle
					teaserImage {
						id
					}
					tags {
						text
					}
					firstPublished
					date
				}
			}
		}
	}
`;
