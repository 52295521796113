import { useState, useEffect, useRef } from 'react';

import useWindowSize from './useWindowSize';

const useClickOutside = showComponent => {
	const ref = useRef(null);
	const size = useWindowSize();
	const [isMobile, setIsMobile] = useState(false);
	const [clickOutside, setClickOutside] = useState(false);

	useEffect(() => {
		if (size.width <= 600) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [size.width]);

	useEffect(() => {
		if (clickOutside) showComponent();
	}, [clickOutside]);

	const handleClickOutside = e => {
		if (!isMobile && ref.current.contains(e.target)) {
			setClickOutside(false);
		} else setClickOutside(true);
	};

	useEffect(() => {
		if (!isMobile) document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isMobile]);

	return { ref };
};

export default useClickOutside;
