import React from 'react';

const SearchIcon = props => (
	<svg fill="none" height={12} width={12} {...props}>
		<path
			className="bg-color-primary--svg"
			clipRule="evenodd"
			d="M6.856 2.462a3.107 3.107 0 11-4.394 4.394 3.107 3.107 0 014.394-4.394zm1.605 4.892a4.66 4.66 0 00-7.096-5.99 4.659 4.659 0 006 7.088l3.537 3.53L12 10.888 8.46 7.354z"
			fill="#000"
			fillRule="evenodd"
		/>
	</svg>
);

export default SearchIcon;
