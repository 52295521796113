import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { canUseDOM } from 'exenv';

import useScrollPosition from '../hooks/useScrollPosition';
import ColorLogo from '../images/rbma-logo-color';
import SearchIcon from '../images/icons/search-icon';
import MobileLinkIcon from '../images/icons/mobile-link-icon';
import Text, { m, ml } from '../styles/typography';
import { colors, minWidthCss, spacing } from '../styles/theme';

const NavContainer = styled.nav`
	position: ${props => (props.stickyNav ? 'fixed' : 'absolute')};
	${props => (props.stickyNav ? 'top: 0' : '')};
	width: 100%;
	z-index: 10;
	background-color: ${colors.white};
	box-shadow: ${props =>
		props.showNav && !props.isTop ? '0px 5px 5px rgba(0, 0, 0, 0.15)' : 'none'};

	${minWidthCss.m`
    transform: ${props =>
			props.showNav ? 'translateY(0) ' : 'translateY(-98%)'};
    transition: transform 0.5s;
  `}
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobile ? 'column' : 'row')};
  align-items: ${props => (props.mobile ? 'flex-start' : 'center')};
  justify-content: space-between;
  width: ${props => (props.logoWrapper ? '18%' : '75%')};
  ${props => (props.mobile ? `margin: 0 ${spacing[1]}` : '')};

  > a {
    display: flex;
    align-items: center;
    margin: ${spacing[0]} 0;
  }

  ${minWidthCss.sm`
    margin: 0;
    padding: ${props => (props.mobile ? `0 2.125rem` : `0`)};
  `}

  ${minWidthCss.l`
    width: ${props => (props.logoWrapper ? '16%' : '75%')};
  `}

  ${minWidthCss.xl`
    width: ${props => (props.logoWrapper ? '13%' : '75%')};
  `}
`;

const LinkItem = styled(Link).attrs({
	className: 'text-color-custom-nav',
})`
	text-decoration: none;
	border-bottom: 2px solid transparent;
	${m}
	${props =>
		props.search || props.mobileLink ? `margin-left: ${spacing[0]}` : ``};

	&:hover,
	&:focus {
		outline: none;
		border-bottom: 2px solid ${colors.black};
	}

	> svg {
		margin-right: ${spacing[0]};
	}

	${minWidthCss.l`
    ${ml};
  `}
`;

const ExternalLinkItem = styled.a.attrs({
	className: 'text-color-custom-nav',
})`
	text-decoration: none;
	border-bottom: 2px solid transparent;
	${m}

	&:hover,
  &:focus {
		outline: none;
		border-bottom: 2px solid ${colors.black};
	}

	${minWidthCss.l`
    ${ml};
  `}

	> svg {
		margin-right: ${spacing[0]};
	}
`;

const DesktopMenuWrapper = styled.div`
	display: none;

	${minWidthCss.m`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${spacing[5]};
    padding: 0 ${spacing[1]};
    margin: 0 ${spacing[2]} 0 ${spacing[2]};
  `}

	${minWidthCss.l`
    padding: 0 3.125rem;
  `}
`;

// MOBILE STYLES
const MobileNavigationContainer = styled.div`
	display: flex;
	align-items: center;
	width: calc(100% - 1.875rem);
	padding: ${spacing[2]} 0;
	justify-content: space-between;

	${minWidthCss.m`
    display: none;
  `};
`;

const MobileButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;

	${minWidthCss.sm`
    padding-left: 1.1875rem;
  `}
`;

const MobileMenuContainer = styled.div`
	transition: max-height 0.3s;
	overflow: hidden;
	opacity: ${props => (props.openMenu ? '1' : '0')};
	max-height: ${props => (props.openMenu ? '610px' : '0')};
	box-shadow: ${props =>
		props.openMenu ? '0px 5px 5px rgba(0, 0, 0, 0.15)' : 'none'};

	${minWidthCss.m`
    display: none;
  `}
`;

const MobileMenuText = styled(Text.Span)`
	${m}
	text-transform: capitalize;
	border-bottom: 2px solid ${colors.black};
	margin-left: ${spacing[0]};
`;

const Arrow = styled(Text.Span)`
	transform: ${props => (props.openMenu ? 'rotate(0)' : 'rotate(-180deg)')};
	transition: transform 0.3s;
	${ml};
	margin-left: ${spacing[1]};
	display: inline-block;
`;

const Paths = {
	Collections: '/',
	Features: '/features/',
	Lectures: 'https://www.redbullmusicacademy.com/lectures',
	Videos: '/videos/',
	Interviews: '/interviews/',
	Search: '/search/',
	RBMusic: 'https://www.redbull.com/int-en/tags/music',
	RBAcademy: 'https://www.redbullmusicacademy.com/',
};

const MobileMenu = ({ toggleMenu, openMenu }) => {
	const currentPage = () => {
		const pathname = canUseDOM && window.location.pathname;

		if (Object.values(Paths).includes(`${pathname}`)) {
			if (Paths.Collections === pathname) {
				return 'Collections';
			}
			return pathname.slice(1, pathname.length - 1);
		}
		return 'Collections';
	};

	const pageTitle = currentPage();

	return (
		<MobileNavigationContainer openMenu={openMenu}>
			<MobileButtonWrapper onClick={toggleMenu}>
				<Arrow openMenu={openMenu}>↓</Arrow>
				<MobileMenuText>{openMenu ? 'Menu' : pageTitle}</MobileMenuText>
			</MobileButtonWrapper>
			<a
				aria-label="Red Bull Music"
				href={Paths.RBMusic}
				rel="noopener noreferrer"
				target="_blank"
			>
				<ColorLogo />
			</a>
		</MobileNavigationContainer>
	);
};

MobileMenu.propTypes = {
	toggleMenu: PropTypes.func,
	openMenu: PropTypes.bool,
};

const NavItem = ({ children, mobile, to, closeMenu }) => (
	<LinkItem
		activeClassName="active"
		mobile={mobile}
		onClick={closeMenu}
		to={to}
	>
		{children}
	</LinkItem>
);

NavItem.propTypes = {
	children: PropTypes.string,
	mobile: PropTypes.bool,
	to: PropTypes.string,
	closeMenu: PropTypes.func,
};

const NavigationLinks = ({ mobile, closeMenu }) => (
	<>
		<LinkWrapper mobile={mobile}>
			<NavItem closeMenu={closeMenu} to={Paths.Collections}>
				Collections
			</NavItem>
			<NavItem closeMenu={closeMenu} to={Paths.Features}>
				Features
			</NavItem>
			<NavItem closeMenu={closeMenu} to={Paths.Interviews}>
				Interviews
			</NavItem>
			<NavItem closeMenu={closeMenu} to={Paths.Videos}>
				Videos
			</NavItem>
			<ExternalLinkItem href={Paths.Lectures}>Lectures</ExternalLinkItem>
			<If condition={mobile}>
				<LinkItem
					onClick={closeMenu}
					style={{ marginBottom: '2.5rem' }}
					to={Paths.Search}
				>
					<SearchIcon />
					Search
				</LinkItem>
			</If>
			<ExternalLinkItem
				href={Paths.RBAcademy}
				rel="noopener noreferrer"
				target="_blank"
			>
				<If condition={mobile}>
					<MobileLinkIcon />
				</If>
				Red Bull Music Academy
			</ExternalLinkItem>
			<If condition={mobile}>
				<ExternalLinkItem
					href={Paths.RBMusic}
					rel="noopener noreferrer"
					target="_blank"
				>
					<MobileLinkIcon />
					Red Bull Music
				</ExternalLinkItem>
			</If>
		</LinkWrapper>
		<If condition={!mobile}>
			<LinkWrapper logoWrapper mobile={mobile}>
				<LinkItem activeClassName="active" to={Paths.Search}>
					<SearchIcon />
					Search
				</LinkItem>
				<a href={Paths.RBMusic} rel="noopener noreferrer" target="_blank">
					<ColorLogo />
				</a>
			</LinkWrapper>
		</If>
	</>
);

NavigationLinks.propTypes = {
	closeMenu: PropTypes.func,
	mobile: PropTypes.bool,
};

const Nav = ({ stickyNav }) => {
	const [showNav, setShowNav] = useState(true);
	const [openMenu, setOpenMenu] = useState(false);
	const [isTop, setIsTop] = useState(true);

	const toggleMenu = () => {
		setOpenMenu(!openMenu);
	};

	const closeMenu = () => {
		setOpenMenu(false);
	};

	useScrollPosition(
		({ currPos, prevPos }) => {
			if (currPos.y < prevPos.y) {
				setShowNav(false);
			} else {
				setShowNav(true);
			}
		},
		[showNav]
	);

	useScrollPosition(
		({ currPos }) => {
			if (currPos.y === 0) {
				setIsTop(true);
			} else {
				setIsTop(false);
			}
		},
		[isTop]
	);

	return (
		<NavContainer
			className="bg-color-secondary"
			isTop={isTop}
			openMenu={openMenu}
			showNav={showNav}
			stickyNav={stickyNav}
		>
			<MobileMenu openMenu={openMenu} toggleMenu={toggleMenu} />
			<MobileMenuContainer openMenu={openMenu}>
				<NavigationLinks closeMenu={closeMenu} mobile />
			</MobileMenuContainer>
			<DesktopMenuWrapper>
				<NavigationLinks />
			</DesktopMenuWrapper>
		</NavContainer>
	);
};

Nav.propTypes = {
	stickyNav: PropTypes.bool,
};

export default Nav;
