import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, spacing, minWidthCss } from '../styles/theme';

import Text, { m, ml, l, xl, xxl } from '../styles/typography';

const DailyTitle = styled(Text.H3)`
	margin: 0 0 ${spacing[4]} 0;
	${m}

	${minWidthCss.m`
    ${ml};
  `};
`;

const DailySubtitle = styled(Text.H1)`
  ${l};

  ${minWidthCss.m`
    ${xl}
  `};

  $${minWidthCss.l`
    ${xxl};
  `};
`;

const BannerContainer = styled.div`
	display: none;
	background-color: ${colors.black};
	width: 100%;
	margin: 0 auto;
	top: 0;
	left: 0;
	right: 0;
	padding: ${spacing[3]} ${spacing[2]} ${spacing[5]} ${spacing[2]};
	position: sticky;
	z-index: -10;

	${minWidthCss.m`
    padding: ${spacing[3]} ${spacing[5]} ${spacing[5]}
      ${spacing[5]};
  `};
`;

const NavigationBanner = ({ getPinPosition, bannerHidden }) => {
	const bannerRef = useRef(null);

	useEffect(() => {
		getPinPosition(bannerRef.current.clientHeight);
	});

	useEffect(() => {
		if (!bannerHidden) {
			bannerRef.current.style.display = 'block';
		}
	}, [bannerHidden]);

	return (
		<BannerContainer ref={bannerRef}>
			<DailyTitle color="white">Red Bull Music Academy Daily</DailyTitle>
			<DailySubtitle color="white">
				Learn about the essential sounds, ideas and people that moved – and
				continue to move – our culture forward.
			</DailySubtitle>
		</BannerContainer>
	);
};

NavigationBanner.propTypes = {
	getPinPosition: PropTypes.func,
	bannerHidden: PropTypes.string,
};

export default NavigationBanner;
