import React from 'react';

const MobileLink = props => (
	<svg fill="none" height={11} width={11} {...props}>
		<path
			className="bg-color-primary--svg"
			clipRule="evenodd"
			d="M3.066 1.5V0h7v7h-1.5V2.56L1.06 10.067 0 9.006 7.505 1.5h-4.44z"
			fill="#000"
			fillRule="evenodd"
		/>
	</svg>
);

export default MobileLink;
