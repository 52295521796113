import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, minWidthCss } from '../styles/theme';

import Navigation from './nav';
import Footer from './footer';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding-bottom: 8.75rem; /* Leave space for footer */
`;

const Container = styled.div`
	margin: 0 auto;
	background-color: ${colors.white};
`;

export const ContainerWithMargin = styled.div`
	padding: 0 0.9375rem;
	z-index: 3;
	background-color: ${colors.white};

	${minWidthCss.l`
    padding: 0 3.125rem;
  `}
`;

const HelperDivForNavSpacing = styled.div`
	height: 8.125rem;
	background-color: ${colors.white};
`;

const Layout = ({ banner, children, stickyNav }) => {
	return (
		<Wrapper>
			<Navigation banner={banner} stickyNav={stickyNav} />
			<HelperDivForNavSpacing className="bg-color-secondary" />
			<Container className="bg-color-secondary">
				<main>{children}</main>
			</Container>
			<Footer />
		</Wrapper>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	banner: PropTypes.number,
	stickyNav: PropTypes.bool,
};

export default Layout;
