import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { minWidthCss } from '../styles/theme';
import { m, ml, l } from '../styles/typography';

const Layout = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 0;
	${m};
	${props => props.columns === 1 && 'justify-content: center;'}

	> a {
		width: calc(100%);
		margin: 0 0 40px;

		${minWidthCss.sm`
      ${ml};
      flex-grow: 0;
      margin: 0 20px 60px 20px;
      width: calc(50% - 40px);
    `}

		${minWidthCss.l`
      width: calc(${props => (props.columns <= 2 ? '50%' : '33.333%')} - 40px);
      ${props => (props.columns <= 2 ? l : ml)}
    `}
	}
`;

const Grid = ({ children, columns }) => (
	<Layout columns={columns}>{children}</Layout>
);

Grid.defaultProps = {
	columns: 2,
};

Grid.propTypes = {
	children: PropTypes.node.isRequired,
	columns: PropTypes.number,
};

export default Grid;
