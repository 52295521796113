import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Button } from '../styles/buttons';
import { spacing, colors } from '../styles/theme';
import CloseIcon from '../images/icons/close-icon';
import Text from '../styles/typography';

const ResetButton = styled(Button)`
	border-bottom: none;
	background-color: transparent;
`;

const RemoveButton = styled(Button)`
	margin: ${props => (props.isMobile ? `${spacing[0]} 0` : `0 ${spacing[0]}`)};
	border-bottom: none;
	background-color: transparent;
	display: flex;
	> svg {
		margin-right: ${spacing[0]};
	}
`;

const SelectedWrapper = styled.div`
	display: flex;
	width: 100vw;
	align-items: ${props => (props.isMobile ? 'flex-start' : 'center')};
	padding: ${props => (props.isMobile ? '20px 15px' : '30px 70px')};
	background-color: ${colors.lightGrey};
	flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;

const FilterText = styled(Text.Span)`
	text-transform: capitalize;
`;

const SelectedTags = ({
	checkedTags,
	handleReset,
	isMobile = false,
	allTags,
}) => {
	const resetFilter = filter => () => handleReset(filter);
	const resetAll = () => handleReset('all');

	const tagsToDisplay = Object.entries(checkedTags).filter(
		item => item[1] === true && allTags.includes(item[0])
	);

	return (
		<If condition={tagsToDisplay.length}>
			<SelectedWrapper isMobile={isMobile}>
				<Text.Span color={isMobile ? 'grey' : 'black'}>
					Selected filters:
				</Text.Span>
				<For each="filter" of={tagsToDisplay}>
					<RemoveButton
						isMobile={isMobile}
						key={filter[0]}
						onClick={resetFilter(filter[0])}
						small
					>
						<CloseIcon />
						<FilterText>{filter[0].replace(/-/g, ' ')}</FilterText>
					</RemoveButton>
				</For>
				<If condition={!isMobile}>
					<ResetButton onClick={resetAll} small>
						Reset All
					</ResetButton>
				</If>
			</SelectedWrapper>
		</If>
	);
};

SelectedTags.propTypes = {
	checkedTags: PropTypes.object,
	handleReset: PropTypes.func,
	isMobile: PropTypes.bool,
	allTags: PropTypes.array,
};

export default SelectedTags;
