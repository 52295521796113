// Based on this directive:
// https://github.com/rbma/rbma-frontend/blob/dev/app/scripts/directives/blocktypes/video-block_directive.coffee

// Video widgets

const addVideoLink = (target, block) => {
	const video = block.service ? block : block.video;

	const links = {
		youtube: (videoID, playlistID) =>
			`<a href="https://www.youtube.com/watch?v=${videoID}${playlistID}">`,
		vimeo: videoID =>
			`<a href="https://vimeo.com/${videoID}">`,
		dailymotion: videoID =>
			`<a href="https://www.dailymotion.com/video/${videoID}">`,
		brightcove: () => '', // Brightcove videos are all broken
	};

	const videoID = video?.id;
	let playlistID = '';
	if (video?.playlistID) {
		playlistID = `&list=${video.playlistID}`;
	}

	if (videoID) {
		const poster = target.querySelector('.video-frame__poster');
		if (poster) {
			const parentMarkup = poster.parentElement.innerHTML
			const link = links[video.service](
				videoID,
				playlistID
			);
			poster.parentElement.innerHTML = `${link}${parentMarkup}</a>`
		}
	}
};

export const initVideos = pageData => {
	pageData.videos.forEach(item => {
		const $videoContainer = document.querySelector(`#body-${item.blockN}`);
		addVideoLink($videoContainer, item);
	});

	if (pageData.video) {
		const $videoBlockContainer = document.querySelector(
			'rbma-video-block .video-frame'
		);
		addVideoLink($videoBlockContainer, pageData.video);
	}

	if (pageData.transcripts) {
		pageData.transcripts.forEach(item => {
			const $videoContainer = document.querySelector(
				`#transcript-${item.blockN}`
			);
			addVideoLink($videoContainer, item);
		});
	}
};
