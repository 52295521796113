import { useState, useRef } from 'react';

import DataTransformer from '../utils/DataTransformer';

const getTag = ({ text }) => text;

const filterByTags = tags => tag => tags.includes(tag);

const getMatched = (tags, item) =>
	item.tags.map(getTag).filter(filterByTags(tags)).length;

const sortByRelevance = tagsToFind => {
	const allTags = tagsToFind.reduce(
		(all, { tag, includes }) => [...all, ...includes, tag],
		[]
	);
	return (a, b) => {
		const matchedA = getMatched(allTags, a);
		const matchedB = getMatched(allTags, b);
		if (matchedA > matchedB) return -1;
		if (matchedA < matchedB) return 1;
		return 0;
	};
};

const useFilter = (data, randomize, removeDuplicates) => {
	const uniqueItems = removeDuplicates
		? DataTransformer.removeDuplicates(data)
		: data;
	const randomizeData = items =>
		randomize ? DataTransformer.randomizeOrder(items) : items;
	const dataRef = useRef(randomizeData(uniqueItems));
	const [filteredData, setFilteredData] = useState(dataRef.current);

	const filter = tagsToFind => {
		if (!tagsToFind) return setFilteredData(dataRef.current);
		// Items which actually contain any tags.
		const itemsWithTags = dataRef.current.filter(item => item.tags);

		const filteredItems = tagsToFind
			.reduce((filtered, { tag, includes }) => {
				const searchForTags = [tag, ...includes];
				const fil = itemsWithTags.filter(({ tags, _id }) => {
					return (
						tags.some(({ text }) => searchForTags.includes(text)) &&
						!filtered.some(item => item._id === _id)
					);
				});
				return [...filtered, ...fil];
			}, [])
			.sort(sortByRelevance(tagsToFind));
		setFilteredData(filteredItems);
	};
	return [filteredData, filter];
};

export default useFilter;
