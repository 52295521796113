import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { initVideos } from '../utils/video';

import Layout from '../components/layout';
import LinkItems from '../components/link-items';
import SEO from '../components/seo';
import Text, { l, ml } from '../styles/typography';
import { spacing, minWidthCss } from '../styles/theme';

import './legacy.css';
import useExcludeTypes from '../hooks/useExcludeTypes';

const RelatedDocumentsTitle = styled(Text.H2)`
  text-align: center;
  ${ml}
  margin-bottom: ${spacing[1]};

  ${minWidthCss.sm`
    margin-bottom: ${spacing[2]};
    ${l}
  `}
`;

const Page = ({ data }) => {
	const {
		body,
		slug,
		title,
		relatedDocuments,
		pageStyle,
		metaImage,
		description,
	} = data.storiesJson;
	useEffect(() => {
		initVideos(data.storiesJson.data);
		const spinner = document.querySelector('.spinner');
		if (spinner) {
			spinner.parentElement.removeChild(spinner);
		}
	});
	const relatedItems = useExcludeTypes(relatedDocuments);

	const customStyle =
		pageStyle && Object.keys(pageStyle).length
			? `
        .text-color-primary {
          color: ${pageStyle.primaryColor};
        }
        .bg-color-primary {
          background-color: ${pageStyle.primaryColor};
        }
        .text-color-secondary {
          color: ${pageStyle.secondaryColor};
        }
        .bg-color-secondary {
          background-color: ${pageStyle.secondaryColor};
        }
        .text-color-body {
          color: ${pageStyle.bodyColor};
        }
        .text-color-custom-nav:not(:hover) {
          color: ${pageStyle.bodyColor};
        }
        .text-color-custom-nav:hover {
          border-bottom-color: ${pageStyle.bodyColor};
        }
        .bg-color-primary--svg {
          fill: ${pageStyle.primaryColor};
        }
        .bg-color-body {
          background-color: ${pageStyle.bodyColor || pageStyle.primaryColor};
        }
        .bg-color-body--svg {
          fill: ${pageStyle.bodyColor};
        }
        body {
          color: ${pageStyle.primaryColor};
          background-color: ${pageStyle.secondaryColor};
        }
        ::-moz-selection {
          background: ${pageStyle.bodyColor};
          color: ${pageStyle.secondaryColor};
          text-shadow: none;
        }
        ::selection {
          background: ${pageStyle.bodyColor};
          color: ${pageStyle.secondaryColor};
          text-shadow: none;
        }
        .main-nav {
          color: ${pageStyle.bodyColor};
        }
        .main-nav.headroom--top {
          color: ${pageStyle.primaryColor};
        }
        .main-nav .bg-color-primary {
          background-color: ${pageStyle.bodyColor} ;
        }
        .main-nav.headroom--top .bg-color-primary {
          background-color: ${pageStyle.primaryColor};;
        }
        .main-nav .bg-color-primary--svg {
          fill: ${pageStyle.bodyColor} ;
        }
        .main-nav.headroom--top .bg-color-primary--svg {
          fill: ${pageStyle.primaryColor};
        }
        .header--cover-2 {
          margin: 0;
        }
        @media (max-width: 600px) {
          .header--cover .headline-main, 
          .header--cover-1 .headline-main, 
          .header--cover-2 .headline-main, 
          .header--cover-3 .headline-main, 
          .header--cover-4 .headline-main, 
          .header--cover-5 .headline-main, 
          .header--cover-6 .headline-main, 
          .header--cover-7 .headline-main, 
          .header--cover-8 .headline-main,
          .header--cover-9 .headline-main {
            font-size: 2.75rem !important;
          }
          .header--story {
            font-size: 2.75rem !important;
            padding: 0 1rem !important;
          }
          .headline-secondary {
            font-size: 1.5rem !important;
          }
        }
      `
			: '';
	return (
		<Layout stickyNav>
			<SEO
				article
				description={description}
				image={metaImage && `${metaImage}?w=1080`}
				pathname={slug}
				title={title
					.split('|')[0]
					.trim()
					.replace(/&amp;/g, '&')
					.replace(/&nbsp;/g, ' ')}
			/>
			<Helmet>
				<link href="/styles/legacy.css" rel="stylesheet" />
				<script defer src="/scripts/main.js" />
			</Helmet>
			<div dangerouslySetInnerHTML={{ __html: body }} />
			<style dangerouslySetInnerHTML={{ __html: customStyle }} />
			<If condition={relatedItems.length}>
				<RelatedDocumentsTitle
					className="bg-color-secondary text-color-body"
					size="l"
				>
					On a different note
				</RelatedDocumentsTitle>
				<LinkItems columns={relatedItems.length} data={relatedItems} noLabel />
			</If>
		</Layout>
	);
};

Page.propTypes = {
	data: PropTypes.object,
};

export default Page;

export const storyQuery = graphql`
	fragment video on StoriesContent {
		schema
		video {
			url
			service
			id
			caption
		}
		poster {
			id
			size
			mimetype
		}
		blockN
	}
	query storyQuery($slug: String!) {
		storiesJson(slug: { eq: $slug }) {
			id
			title
			body
			slug
			metaImage
			description
			relatedDocuments {
				title
				teaserTitle
				teaserSubtitle
				teaserImage {
					id
				}
				slug
				date
				type
				firstPublished
			}
			data {
				videos {
					...video
				}
				transcripts {
					...video
				}
				video {
					...video
				}
			}
			pageStyle {
				primaryTypeface
				secondaryTypeface
				bodyTypeface
				primaryColor
				bodyColor
				secondaryColor
			}
		}
	}
`;
