import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';

import { minWidthCss, colors, fontSize } from '../styles/theme';
import Text, { fontstackSansSerif } from '../styles/typography';

const SearchWrapper = styled.div`
	margin: 40px 0 0;
	${minWidthCss.sm`
    margin: 60px 20px 0;
  `}
`;

const StyledSearch = styled.div`
	height: 55px;
	border: 2px solid ${colors.black};
	display: flex;
	align-items: center;

	${minWidthCss.sm`
    height: 70px;
  `}
`;

const Input = styled.input`
  font-family: ${fontstackSansSerif};
  font-size: ${fontSize[2]};
  width: 100%;
  height: 30px;
  padding: 0;
  margin: 0 15px 0 0;
  border: none;
  display: ${props => (props.isMobile ? 'block' : 'none')};
  outline: none;

  &::placeholder {
    font-family: ${fontstackSansSerif};
    color: ${colors.placeholder};
  }


  &:focus::placeholder {
      color: transparent;
  }

  ${minWidthCss.sm`
    height: 64px;
    padding: 0 20px 0 0;
    display: ${props => (props.isMobile ? 'none' : 'block')};
  `}

  ${minWidthCss.m`
    font-size: ${fontSize[3]};
  `}

  ${minWidthCss.l`
    font-size: ${fontSize[4]};
  `};
`;

const Label = styled.label`
	width: 20px;
	height: 20px;
	margin: 0 15px;

	${minWidthCss.sm`
    width: 34px;
    height: 34px;
    margin: 0 20px;
  `}
`;

const SearchInfo = styled.div`
	height: 20px;
	margin: 10px 0;

	${minWidthCss.sm`
    margin: 20px 0;
  `}
`;

const svgCss = css`
	stroke-width: 4px;

	${minWidthCss.sm`
    stroke-width: 2px;
  `}
`;

const SearchInput = ({ onSearch, resultAmount, resultTotal }) => {
	const [query, setQuery] = useState('');
	const timeout = useRef(0);

	const onChange = ({ target }) => {
		setQuery(target.value);
	};

	useEffect(() => {
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => onSearch(query || null), 250);
		return () => clearTimeout(timeout.current);
	}, [query]);

	return (
		<SearchWrapper>
			<StyledSearch>
				<Label htmlFor="search">
					<svg fill="none" viewBox="0 0 34 34">
						<title>
							Search over 2,000 Lectures, Features, Interviews, Themes and
							Videos
						</title>
						<circle
							css={svgCss}
							cx="15.5563"
							cy="15.5564"
							r="10"
							stroke="black"
							strokeWidth="2"
							transform="rotate(-45 15.5563 15.5564)"
						/>
						<line
							css={svgCss}
							stroke="black"
							strokeWidth="2"
							x1="22.629"
							x2="32.5285"
							y1="22.6275"
							y2="32.527"
						/>
					</svg>
				</Label>
				<Input
					autoComplete="off"
					id="search"
					onChange={onChange}
					placeholder="Search over 2,000 Lectures, Features, Interviews, Themes and Videos"
					type="text"
					value={query}
				/>
				<Input
					autoComplete="off"
					id="search"
					isMobile
					onChange={onChange}
					placeholder="Search..."
					type="text"
					value={query}
				/>
			</StyledSearch>
			<SearchInfo>
				<If condition={resultAmount && query}>
					<Text.P size="s">
						Showing <strong>{resultAmount}</strong> of {resultTotal} for &apos;
						{query}&apos;
					</Text.P>
				</If>
			</SearchInfo>
		</SearchWrapper>
	);
};

SearchInput.propTypes = {
	onSearch: PropTypes.func.isRequired,
	resultAmount: PropTypes.number,
	resultTotal: PropTypes.number,
};

export default SearchInput;
