import styled from 'styled-components';

import { colors } from './theme';
import { fontstackSansSerif, m, ml } from './typography';
import './typography.css';

export const Button = styled.button`
	font-family: ${fontstackSansSerif};
	${props => (props.small ? m : ml)};
	align-items: center;
	color: ${colors.black};
	cursor: pointer;
	border: none;
	padding: 0;
	border-bottom: black solid 2px;

	&:focus {
		outline: none;
		background-color: ${colors.lightGrey};
	}
`;
