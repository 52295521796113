import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import moment from 'moment';

import Text, { m, ml } from '../styles/typography';

import { Button } from '../styles/buttons';

import DataTransformer from '../utils/DataTransformer';
import { minWidthCss, spacing } from '../styles/theme';
import usePagination from '../hooks/usePagination';
import useFilter from '../hooks/useFilter';
import PlayIcon from '../images/icons/play-icon';

import { ContainerWithMargin } from './layout';
import Filter from './filter';
import SortFormat from './sort-format';

import Grid from './grid';

const COLUMN_FACTOR = 6;

const Title = styled(({ isExternal, url, children, ...props }) => (
	<Choose>
		<When condition={isExternal}>
			<a href={url} {...props}>
				{children}
			</a>
		</When>
		<Otherwise>
			<Link to={url} {...props}>
				{children}
			</Link>
		</Otherwise>
	</Choose>
))`
	text-decoration: none;
	color: black;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	> img {
		width: 100%;
	}
`;

const ImageWrapper = styled.div`
	position: relative;
	margin-bottom: ${spacing[0]};

	> svg {
		position: absolute;
		top: 30%;
		left: 35%;
	}

	${minWidthCss.m`
    margin-bottom: ${spacing[2]};

    > svg {
      top: 35%;
      left: 40%;
    }
  `}
`;

const Wrapper = styled.div`
	margin: 20px 0 40px;

	${minWidthCss.sm`
    margin: 40px 20px 80px;
  `}
`;

const LoadMoreButton = styled(Button)`
	${m}

	${minWidthCss.m`
    ${ml}
  `}
`;

const FilterBar = styled.div`
	display: flex;
	justify-content: ${props =>
		!props.showFilter && props.showSort ? 'flex-end' : 'space-between'};
`;

const getBase = docType => {
	switch (docType) {
		case 'academyProject':
			return 'https://www.redbullmusicacademy.com/about/projects';
		case 'lecture':
			return 'https://www.redbullmusicacademy.com/lectures';
		case 'author':
			return '/author';
		case 'story':
		case 'coverStory':
		case 'collection':
		default:
			return '';
	}
};

const getUrl = (docType, slug, date) => {
	if (docType !== 'story' && docType !== 'coverStory') {
		return `${getBase(docType)}/${slug}`;
	}
	const dateObj = moment(date);
	return `${getBase(docType)}/${dateObj.year()}/${dateObj.format(
		'MM'
	)}/${slug}`;
};

const LinkItem = ({
	teaserImage,
	slug,
	title,
	teaserTitle,
	teaserSubtitle,
	firstPublished,
	type,
	date,
}) => {
	const currentSlug = slug[slug.length - 1];
	const url = getUrl(type, currentSlug, firstPublished || date);
	const placeholder =
		'https://media.redbullmusicacademy.com/assets/placeholder.jpg';
	const loadingPlaceholder =
		'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAZABkAAD/4QCMRXhpZgAATU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAABkAAAAAQAAAGQAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAAGgAwAEAAAAAQAAAAEAAAAA/+0AOFBob3Rvc2hvcCAzLjAAOEJJTQQEAAAAAAAAOEJJTQQlAAAAAAAQ1B2M2Y8AsgTpgAmY7PhCfv/AABEIAAEAAQMBEQACEQEDEQH/xAAfAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgv/xAC1EAACAQMDAgQDBQUEBAAAAX0BAgMABBEFEiExQQYTUWEHInEUMoGRoQgjQrHBFVLR8CQzYnKCCQoWFxgZGiUmJygpKjQ1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4eLj5OXm5+jp6vHy8/T19vf4+fr/xAAfAQADAQEBAQEBAQEBAAAAAAAAAQIDBAUGBwgJCgv/xAC1EQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/3QAEAAH/2gAMAwEAAhEDEQA/AP7kKAP/2Q==';
	const imgSrc = teaserImage
		? `https://media.redbullmusicacademy.com/assets/${teaserImage.id.replace(
				/\s/g,
				'%20'
		  )}`
		: placeholder;

	return (
		<Title isExternal={url.charAt(0) !== '/'} key={currentSlug} url={url}>
			<ImageWrapper>
				<Img
					alt=""
					fluid={{
						base64: loadingPlaceholder,
						aspectRatio: 1.5,
						src: imgSrc,
						srcSet: `
              ${imgSrc}?h=250&w=375 375w,
              ${imgSrc}?h=500&w=750 750w,
              ${imgSrc}?h=750&w=1125 1125w`,
						sizes: `(max-width: 600px) 100vw,
              (max-width: 900px) 375px,
              (max-width: 1700px) 750px,
              50vw
              `,
					}}
					key={`image_${currentSlug}`}
					loading="eager"
				/>
				<If condition={type === 'lecture'}>
					<PlayIcon />
				</If>
			</ImageWrapper>
			<Text.Span className="text-color-body">
				{teaserTitle || title}
				<If condition={teaserSubtitle && type !== 'story'}>
					{' '}
					→ {teaserSubtitle}
				</If>
			</Text.Span>
		</Title>
	);
};

const LinkItems = ({
	data,
	columns,
	randomize = true,
	onNextPage,
	hasNextPage,
	showFilter = false,
	showSort = false,
}) => {
	const hasFilterOrSort = showFilter || showSort;
	const itemsPerPage = columns * COLUMN_FACTOR;
	const [page, nextPage] = usePagination();
	const [filteredData, filterByTags] = useFilter(
		data,
		randomize,
		hasFilterOrSort
	);

	const loadMore = async evt => {
		evt.preventDefault();
		if (onNextPage) onNextPage(page + 1);
		nextPage();
	};

	const { items, hasMore } = DataTransformer.from(
		hasFilterOrSort ? filteredData : data
	).getDataByPage(page, itemsPerPage);

	const tagsByCategory = useStaticQuery(graphql`
		query allTags {
			allTagsJson {
				nodes {
					tags {
						tag
						includes
					}
				}
			}
		}
	`);
	const allTags = tagsByCategory.allTagsJson.nodes.reduce(
		(merged, current) => [...merged, ...current.tags],
		[]
	);

	const getTagWithIncludes = tags =>
		tags.reduce((withIncludes, current) => {
			const tagReference = allTags.find(({ tag }) => tag === current);
			return [
				...withIncludes,
				{
					tag: current,
					includes: (tagReference && tagReference.includes) || [],
				},
			];
		}, []);

	const handleSubmit = tags => {
		// Check if there are any tags. If none then reset filtered data.
		if (Object.keys(tags).length) {
			if (tags.includes) {
				filterByTags(tags);
			} else {
				filterByTags(getTagWithIncludes(Object.keys(tags)));
			}
		} else {
			filterByTags(null);
		}
	};

	return (
		<>
			<FilterBar showFilter={showFilter} showSort={showSort}>
				<If condition={showFilter}>
					<Filter onSubmit={handleSubmit} />
				</If>
				<If condition={showSort}>
					<SortFormat onSubmit={handleSubmit} />
				</If>
			</FilterBar>
			<ContainerWithMargin className="bg-color-secondary text-color-body">
				<Choose>
					<When condition={items.length}>
						<Grid columns={columns}>
							<For each="item" index="index" of={items}>
								<Choose>
									<When condition={item.collection}>
										<LinkItem
											key={`${item.collection.title}_${index}`}
											{...item.collection}
										/>
									</When>
									<Otherwise>
										<LinkItem key={`${item.title}_${index}`} {...item} />
									</Otherwise>
								</Choose>
							</For>
						</Grid>
						<If condition={hasNextPage || hasMore}>
							<Wrapper>
								<LoadMoreButton onClick={loadMore}>Load more</LoadMoreButton>
							</Wrapper>
						</If>
					</When>
					<Otherwise>
						<Wrapper>
							<Text.H2>No matches found.</Text.H2>
						</Wrapper>
					</Otherwise>
				</Choose>
			</ContainerWithMargin>
		</>
	);
};

LinkItem.propTypes = {
	teaserImage: PropTypes.shape({
		id: PropTypes.string,
		space: PropTypes.string,
		mimetype: PropTypes.string,
	}),
	slug: PropTypes.arrayOf(PropTypes.string).isRequired,
	title: PropTypes.string,
	teaserTitle: PropTypes.string,
	teaserSubtitle: PropTypes.string,
	firstPublished: PropTypes.string,
	date: PropTypes.string,
	type: PropTypes.string,
};

LinkItems.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.number.isRequired,
	randomize: PropTypes.bool,
	onNextPage: PropTypes.func,
	hasNextPage: PropTypes.bool,
	showFilter: PropTypes.bool,
	showSort: PropTypes.bool,
};

export default LinkItems;
