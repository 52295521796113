import { useRef } from 'react';

const EXCLUDED_TYPES = ['externalStory', 'lectureCollection', 'event'];
const EXCLUDED_TAGS = ['gallery', 'galleries'];

const useExcludeTypes = items => {
	const { current } = useRef(
		items
			.filter(item => item)
			.filter(({ type, tags }) => {
				if (EXCLUDED_TYPES.includes(type)) return false;
				if ((tags || []).some(({ text }) => EXCLUDED_TAGS.includes(text))) {
					return false;
				}
				return true;
			})
	);

	return current;
};

export default useExcludeTypes;
