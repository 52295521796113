import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { canUseDOM } from 'exenv';

import {
	priorityCollections,
	excludedCollections,
} from '../constants/homepage';
import DataTransformer from '../utils/DataTransformer';
import useScrollPosition from '../hooks/useScrollPosition';
import Layout from '../components/layout';
import LinkItems from '../components/link-items';
import Header from '../components/header';
import SEO from '../components/seo';
import NavigationBanner from '../components/nav-banner';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query CollectionsQuery {
			allCollectionsJson(
				filter: {
					collection: {
						title: { nin: ["Features", "Videos", "Interviews", "Themes"] }
					}
				}
			) {
				nodes {
					collection {
						title
						slug
						_id
						type
						teaserSubtitle
						teaserImage {
							id
						}
						tags {
							text
						}
					}
				}
			}
		}
	`);

	const [position, setPosition] = useState(0);
	const [stickyNav, setStickyNav] = useState(false);

	const getIsHidden = () => {
		try {
			return localStorage.getItem('hideBanner');
		} catch (error) {
			// do nothing
			return false;
		}
	};

	const bannerHidden = canUseDOM && getIsHidden();

	useScrollPosition(
		({ currPos }) => {
			if (currPos.y <= -400) {
				if (!stickyNav) {
					setStickyNav(true);
				}
			} else {
				setStickyNav(false);
			}
		},
		[stickyNav]
	);

	useEffect(() => {
		if (stickyNav && !bannerHidden && canUseDOM) {
			try {
				localStorage.setItem('hideBanner', true);
			} catch (error) {
				// do nothing
			}
		}
	}, [stickyNav, bannerHidden]);

	// renders priority collections first, and removes excluded collections

	const sortPriorityData = () => {
		const priorityData = data.allCollectionsJson.nodes.filter(item =>
			priorityCollections.includes(item.collection.title)
		);

		const dataWithoutPriority = data.allCollectionsJson.nodes.filter(
			item => !priorityCollections.includes(item.collection.title)
		);

		const deprioritisedCollections = dataWithoutPriority.filter(
			item => !excludedCollections.includes(item.collection.slug[0])
		);

		const randomizedPriorityData = DataTransformer.randomizeOrder(priorityData);
		const randomizedDataWithoutPriority = DataTransformer.randomizeOrder(
			deprioritisedCollections
		);

		const mergedData = [
			...randomizedPriorityData,
			...randomizedDataWithoutPriority,
		];

		return mergedData;
	};

	const sortedPriorityData = useRef(sortPriorityData());

	return (
		<>
			<SEO />
			<NavigationBanner
				bannerHidden={bannerHidden}
				getPinPosition={bannerPosition => {
					setPosition(bannerPosition);
				}}
			/>
			<Layout banner={position} stickyNav={stickyNav}>
				<Header
					currentPage="collections"
					hasLabel
					isNavigationPage
					pageTitle="Explore our diverse collection of themed stories"
				/>
				<LinkItems
					columns={2}
					data={sortedPriorityData.current}
					randomize={false}
				/>
			</Layout>
		</>
	);
};

export default IndexPage;
