import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Text, { m, ml, l, xl, xxxl } from '../styles/typography';
import { minWidthCss, spacing } from '../styles/theme';

const Title = styled(Text.H1)`
	${props => (props.large ? xl : l)};
	margin-bottom: ${spacing[2]};

	${minWidthCss.m`
    ${props => (props.large ? xxxl : xl)};
  `}
`;

const Subtitle = styled(Text.P)`
	${ml};
	margin-bottom: ${spacing[2]};

	${minWidthCss.m`
    ${l};
  `}
`;

/* eslint-disable no-nested-ternary */
const Wrapper = styled.div`
	max-width: ${props =>
		props.isAuthorPage ? null : props.isNavigationPage ? '45.625rem' : ''};
	padding: 0 ${spacing[1]};
	margin-bottom: ${spacing[3]};

	${minWidthCss.sm`
    max-width: ${props =>
			props.isAuthorPage ? null : props.isNavigationPage ? '45.625rem' : '60%'};
    padding: 0 2rem;
  `}

	${minWidthCss.l`
    padding: 0 3.125rem;
    margin-left:  ${spacing[2]};
    margin-right: ${spacing[2]};
  `}
`;
/* eslint-enable no-nested-ternary */

const ContentLabel = styled(Text.Label)`
	${m};
`;

const Header = ({
	pageTitle,
	pageSubtitle,
	isAuthorPage = false,
	isNavigationPage,
	currentPage = '',
	hasLabel = false,
}) => (
	<header>
		<Wrapper isAuthorPage={isAuthorPage} isNavigationPage={isNavigationPage}>
			<Title large={isAuthorPage || !isNavigationPage}>{pageTitle}</Title>
			<Subtitle>{pageSubtitle}</Subtitle>
			<If condition={hasLabel}>
				<ContentLabel color="grey">
					Refresh to randomize {currentPage}
				</ContentLabel>
			</If>
		</Wrapper>
	</header>
);

Header.propTypes = {
	pageTitle: PropTypes.string,
	pageSubtitle: PropTypes.string,
	isAuthorPage: PropTypes.bool,
	isNavigationPage: PropTypes.bool,
	hasLabel: PropTypes.bool,
	currentPage: PropTypes.string,
};

Header.defaultProps = {
	pageTitle: '',
	pageSubtitle: '',
	isAuthorPage: false,
	isNavigationPage: false,
};

export default Header;
