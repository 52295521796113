export const priorityCollections = [
	'Afrofuturism',
	'Avant-Garde',
	'Beat-Making',
	'Collaboration',
	'Diaspora',
	'DIY',
	'DJ History',
	'DJing',
	'Give The Drummer Some',
	'Instrumental Instruments',
	'Key Tracks',
	'Loop History',
	'Meetings of Great Minds',
	'Mixing & Mastering',
	'Music in Film',
	'Nightclubbing',
	'Origin Stories',
	'Pioneering Women',
	'RBMA Alumni',
	'Record Stores',
	'Rhythm',
	'Soundsystems',
	'Studio Science',
	'Synthesizers',
	'The African Underground',
	'The Arts',
	'The Voice',
	'Traditions',
	'Hip-Hop',
	'House',
	'Disco',
	'Jazz',
	'Techno',
	'Detroit',
	'Chicago',
	'New York',
	'Jamaica',
	'Funk',
];

export const excludedCollections = [
	'10-mixes',
	'bass-camp',
	'benji-b',
	'best-music-journalism',
	'brendan-m-gillen',
	'brixton',
	'buraka-som-sistema',
	'carl-craig',
	'choice-mix',
	'copenhagen',
	'dj-harvey',
	'dorian-concept',
	'early-electronic-music-in-canada',
	'features',
	'first-floor',
	'galleries-red-bull-music-academy-festival-new-york-2016',
	'gerd-janson',
	'hashtags',
	'hashtags-season-ii',
	'hudson-mohawke',
	'interviews',
	'john-talabot',
	'key-1999-tracks',
	'kode9',
	'lecture',
	'longreads',
	'madrid-2011',
	'martyn',
	'modern-approaches-hip-hop',
	'montreal',
	'montreal-record-collections',
	'music journalism',
	'new-york-2013',
	'new-york-2015',
	'new-york-stories',
	'paris-2015',
	'paris-2015-photo-highlights',
	'paris-now-collection',
	'photo-mixtape',
	'production',
	'rbma-berlin-2018',
	'rbma-uk-tour-2016-photos',
	'revolutions-on-air',
	'richie-hawtin-collection',
	'richie-hawtin',
	'technology',
	'technophilia',
	'tokyo-2014',
	'true-radicals',
	'unearthed',
	'what-difference-does-it-make',
	'xxxy',
];
