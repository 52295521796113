import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import useClickOutside from '../hooks/useClickOutside';

import Text from '../styles/typography';
import { Button } from '../styles/buttons';
import { colors, spacing, minWidthCss } from '../styles/theme';

import FilterMobile from './filter-mobile';
import Checkbox from './checkbox';
import SelectedTags from './selected-tags';

const FilterContainer = styled.div`
  position: relative;
  display: none;

  ${minWidthCss.m`
    display: block;
    width: auto;  
  `}

  ${minWidthCss.m`
    width: 50%;  
  `}

  ${minWidthCss.l`
    width: 75%;  
  `}
`;

const Menu = styled.div`
	background-color: ${colors.lightGrey};
	opacity: ${props => (props.showFilter ? '1' : '0')};
	z-index: ${props => (props.showFilter ? '10' : '-10')};
	position: absolute;
	width: 100vw;
	box-shadow: 0rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.15);
	transition: opacity 0.3s;

	${minWidthCss.m`
    height: auto; 
  `}
`;

const CategoryButton = styled(Button)`
	background-color: ${props =>
		props.selected ? `${colors.lightGrey}` : 'transparent'};
	padding: ${spacing[0]} ${spacing[1]};
	border-bottom: none;
	&:hover {
		color: ${colors.grey};
	}
`;

const Arrow = styled(Text.Span)`
	transform: ${props => (props.selected ? 'rotate(-180deg)' : 'rotate(0)')};
	transition: transform 0.3s;
	margin-left: ${spacing[0]};
	display: inline-block;
`;

const TagWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 1.875rem 4.375rem;
	max-height: ${props => `calc(${props.tagNumber}rem / 2 + 10rem)`};

	${minWidthCss.l`
    height: ${props =>
			props.isGenre
				? `calc(${props.tagNumber}rem / 1.75)`
				: `calc(${props.tagNumber}rem / 2)`};
  `}
`;

const ButtonWrapper = styled.div`
	margin: 0 ${spacing[2]};

	${minWidthCss.m`
    margin: 0 3.4375rem;
  `}
`;

const fromEntries = entries =>
	entries.reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

const Filter = ({ onSubmit }) => {
	const [showFilter, setShowFilter] = useState(false);
	const [checkedTags, setCheckedTags] = useState({});
	const [showCategories, setCategoriesMobile] = useState(true);
	const { ref } = useClickOutside(() => setShowFilter(false));

	useEffect(() => {
		const tagsToSubmit = Object.entries(checkedTags).filter(
			tag => tag[1] === true
		);
		onSubmit(fromEntries(tagsToSubmit));
	}, [checkedTags]);

	const data = useStaticQuery(graphql`
		query TagsQuery {
			allTagsJson {
				nodes {
					category
					tags {
						tag
						includes
					}
				}
			}
		}
	`);

	const [activeTab, setTab] = useState(data.allTagsJson.nodes[0].category);

	const alphabetise = data.allTagsJson.nodes.map(tagsList => {
		const { category } = tagsList;
		const alphabetisedTags = tagsList.tags.sort((a, b) => {
			if (a.tag.toLowerCase() < b.tag.toLowerCase()) return -1;
			if (b.tag.toLowerCase() < a.tag.toLowerCase()) return 1;
			return 0;
		});

		return { category, tags: alphabetisedTags };
	});

	const applyTag = (tags, originalTag, value) => {
		const tagsToApply = tags.reduce(
			(acc, item) => {
				return {
					...acc,
					[item]: value,
				};
			},
			{
				...checkedTags,
				[originalTag]: value,
			}
		);

		setCheckedTags(tagsToApply);
	};

	const handleReset = tag => {
		const allTagData = data.allTagsJson.nodes.reduce(
			(acc, item) => [...acc, ...item.tags],
			[]
		);
		if (tag === 'all') {
			// need to clear all checked tags as well as reset the filtered data
			setCheckedTags({});
		} else {
			const findTag = allTagData.find(item => item.tag === tag);
			const mergedItems = findTag.includes || [];
			applyTag(mergedItems, tag, false);
		}
	};

	const handleSelect = (category, selected) => () => {
		if (!showFilter) setShowFilter(true);
		if (selected) {
			// need to filter only the tags that are "true"
			const tagsToSubmit = Object.entries(checkedTags).filter(
				([_, isEnabled]) => isEnabled
			);

			onSubmit(fromEntries(tagsToSubmit));
			setShowFilter(false);
		}
		setTab(category);
	};

	const handleChange = () => event => {
		const { name } = event.target;
		const mergedItems = [];
		applyTag(mergedItems, name, event.target.checked);
	};

	const allTags = data.allTagsJson.nodes.reduce((acc, item) => {
		const getAllTags = item.tags.reduce(
			(alltags, tag) => [...alltags, tag.tag],
			[]
		);
		return [...acc, ...getAllTags];
	}, []);

	return (
		<>
			<FilterMobile
				activeTab={activeTab}
				allTags={allTags}
				checkedTags={checkedTags}
				data={data.allTagsJson.nodes}
				handleChange={handleChange}
				handleReset={handleReset}
				handleSelect={handleSelect}
				setCategoriesMobile={setCategoriesMobile}
				setShowFilter={setShowFilter}
				setTab={setTab}
				showCategories={showCategories}
				showFilter={showFilter}
			/>
			<FilterContainer ref={ref} showFilter={showFilter}>
				<ButtonWrapper>
					<For each="tagCategory" of={alphabetise}>
						<CategoryButton
							key={tagCategory.category}
							onClick={handleSelect(
								tagCategory.category,
								showFilter && activeTab === tagCategory.category
							)}
							selected={showFilter && activeTab === tagCategory.category}
						>
							<Text.Span size="m">{tagCategory.category}</Text.Span>
							<Arrow
								selected={showFilter && activeTab === tagCategory.category}
								size="ml"
							>
								↓
							</Arrow>
						</CategoryButton>
					</For>
				</ButtonWrapper>
				<Menu showFilter={showFilter}>
					<For each="tagCategory" index="index" of={alphabetise}>
						<If condition={tagCategory.category === activeTab}>
							<TagWrapper
								isGenre={tagCategory.category === 'Genre'}
								key={`${tagCategory.tag}_${index}`}
								tagNumber={tagCategory.tags.length}
							>
								<For each="tagName" index="index" of={tagCategory.tags}>
									<Checkbox
										checked={checkedTags[tagName.tag]}
										key={`${tagName.tag}_${index}`}
										name={tagName.tag}
										onChange={handleChange(tagName.includes)}
									/>
								</For>
							</TagWrapper>
						</If>
					</For>
					<SelectedTags
						allTags={allTags}
						checkedTags={checkedTags}
						handleReset={handleReset}
					/>
				</Menu>
				<SelectedTags
					allTags={allTags}
					checkedTags={checkedTags}
					handleReset={handleReset}
				/>
			</FilterContainer>
		</>
	);
};

Filter.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default Filter;
