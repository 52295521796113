module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5RLQKC","selfHostedOrigin":"https://qm.redbullmusicacademy.com","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"brand":"international-sites","environment":"on-site","event":"init-meta","language":"en","locale":"int","propertyName":"red-bull-music-academy","siteType":"website"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5XHDS9","selfHostedOrigin":"https://qm.redbullmusicacademy.com","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"brand":"international-sites","environment":"on-site","event":"init-meta","language":"en","locale":"int","propertyName":"red-bull-music-academy","siteType":"website"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-global-styles/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/GlobalStyleComponent","props":{"theme":"src/styles/theme","other":{"light":true}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
