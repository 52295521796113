import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '../styles/typography';
import { spacing, colors } from '../styles/theme';

const StyledCheckbox = styled.div`
	width: ${spacing[1]};
	height: ${spacing[1]};
	border-radius: 50%;
	display: inline-block;
	vertical-align: top;
	margin-right: ${spacing[0]};
	background-color: ${props =>
		props.checked ? `${colors.black}` : `${colors.white}`};
	transition: all 0.1s;
`;

const CustomCheckbox = styled(Text.Label)`
	display: flex;
	align-items: center;
	${props => (props.isMobile ? 'flex: 40%' : '')};
	margin: 0 ${spacing[0]} ${spacing[1]} 0;
	${props => (props.specialCase ? '' : 'text-transform: capitalize')};
	/* need a focus state for accessibility  */
	> input:focus + ${StyledCheckbox} {
		outline: 3px ${colors.mediumGrey} solid;
		outline-offset: 2px;
	}
	> input:hover + ${StyledCheckbox} {
		background-color: ${colors.black};
	}
	/* hides the input but still leaves it accessible */
	> input {
		position: absolute;
		width: ${spacing[1]};
		height: ${spacing[1]};
		opacity: 0;
	}

	> span {
		line-height: normal;
	}
`;

const Checkbox = ({ name, checked = false, onChange, isMobile = false }) => {
	const [specialCase, setSpecialCase] = useState(false);

	useEffect(() => {
		if (name === 'Italo-disco' || name === 'Cote d’Ivoire') {
			setSpecialCase(true);
		}
	}, [name]);

	return (
		<CustomCheckbox isMobile={isMobile} key={name} specialCase={specialCase}>
			<input name={name} onChange={onChange} type="checkbox" />
			<StyledCheckbox checked={checked} />
			<Text.Span>{name.replace(/-/g, ' ')}</Text.Span>
		</CustomCheckbox>
	);
};

Checkbox.propTypes = {
	isMobile: PropTypes.bool,
	name: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

export default Checkbox;
